import React, { useState } from 'react';
import { getAvailableResources } from "../../i18n";
import { dayjs } from "../../config";
import Cookies from '../../utils/cookies';

import { Button, Menu, MenuItem } from "@material-ui/core"
import { ExpandMore, Language } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const UILangSelector = ({ drawerMenu = false, icon }) => {
  const { i18n, t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [element, setElement] = useState(null);

  const handleMenuOpen = (event) => {
    setOpenMenu(true);
    setElement(event.currentTarget);
  }

  const handleClose = () => {
    setOpenMenu(false);
    setElement(null);
  };

  const handleChangeLang = (value) => {
    i18n.changeLanguage(value);
    Cookies.saveLang(value);
    dayjs.locale(value);
    setOpenMenu(false);
    setElement(null);
  };

  const langs = getAvailableResources().map((lang, idx) => {
    return (
      <MenuItem
        key={idx}
        value={lang.localeCode}
        onClick={() => handleChangeLang(lang.localeCode)}
      >{lang.displayName}</MenuItem>
    );
  });

  return <>
    <Button
      startIcon={<Language/>}
      className={drawerMenu ? "ui-lang-selector-vertical" : "ui-lang-selector"}
      onClick={handleMenuOpen}
    >
      {drawerMenu ? (t('common:locale.long_name') || '') : (t('common:locale.name') || '')}
      {<ExpandMore />}
    </Button>
    <Menu
      open={openMenu}
      anchorEl={element}
      onClose={handleClose}
    >
      {langs}
    </Menu>
  </>
}

export default UILangSelector;