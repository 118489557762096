import _ from "lodash";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as altActions from '../../actions/altHome';
import * as authActions from '../../actions/auth';
import * as contractsActions from '../../actions/contracts';

import { LogoBar } from "../LogoBar";
import { dispatchNewRoute } from '../../utils/http_functions';
import { is_debug } from '../../utils/debug';
import Settings, { features } from "../../settings";
import UILangSelector from "../UILangSelector";
import {
  ExpandMore,
  ExpandLess,
  Menu,
  Receipt,
  Feedback,
  Close,
  Person,
  PersonAdd,
  AddBox,
  PermIdentity,
  Message,
  PermDeviceInformation,
  VpnKey,
  EuroSymbol,
  Assignment,
} from "@material-ui/icons";
import {
  Collapse,
  ListItemText,
  ListItem,
  List,
  Drawer,
  Box,
  Typography,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Grid
} from "@material-ui/core";
import { contraction_url } from '../../constants/i18n';
import DinamicServerNavigation from "../ServerElements/DinamicServerNavigation";
import { useTranslation } from "react-i18next";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      auth: bindActionCreators(authActionCreators, dispatch),
      alt: bindActionCreators(altActionCreators, dispatch),
      contracts: bindActionCreators(contractsActionCreators, dispatch),
      invoices: bindActionCreators(invoicesActionCreators, dispatch),
    }
  }
}

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const email = useSelector((state) => state.profile.data?.email);
  const name = useSelector((state) => state.profile.data?.name);
  const token = useSelector((state) => state.auth.token);

  const [open, setOpen] = useState(false);
  const [contractsOpen, setContractsOpen] = useState(false);
  const [invoicesOpen, setInvoicesOpen] = useState(false);
  const [consumptionOpen, setConsumptionOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  const openProfileCollapse = () => {
    setProfileOpen(!profileOpen);
  }

  const openContractsCollapse = () => {
    setContractsOpen(!contractsOpen);
  }

  const openInvoicesCollapse = () => {
    setInvoicesOpen(!invoicesOpen);
  }

  const openConsumptionCollapse = () => {
    setConsumptionOpen(!consumptionOpen);
  }

  const dispatchRoute = (route) => {
    dispatchNewRoute(route);
    setOpen(false);
  }

  const handleClickOutside = () => {
    setOpen(false);
  }

  const logout = (e) => {
    e.preventDefault();
    dispatch(authActions.logoutAndRedirect());
    setOpen(false);
  }

  const openNav = () => {
    setOpen(true);
  }

  const contractFun = (funktion) => {
    dispatch(altActions.altContractFunction(funktion));
    dispatchNewRoute(t('common:url.alt_contracts_detail'));
    setOpen(false);
  }

  const goToInvoices = () => {
    const token = token;
    dispatch(contractsActions.clearContractsInvoices());
    setOpen(false);
  }

  const user = token ? (
    <div
      style={{ color: "#fff", paddingRight: "30px", fontSize: "14px" }}
      className={Settings?.header?.showUserDataMobile ? "show-user-data" : "hide-user-data"}
    >
      {t('common:text.user_welcome')}<br />
      <Box fontWeight="fontWeightBold" sx={{ whiteSpace: "nowrap" }}>{name}</Box>
      {email}
    </div>
  ) : null;

  return (
    <header role="banner">
      <Drawer open={open}>
        <AppBar
          position="static"
          onClick={() => setOpen(false)}
          elevation={0}
          className="drawer-app-bar"
        >
          <Toolbar>
            <IconButton edge="start" color="inherit" className="toolbar-icon-button">
              <Close />
            </IconButton>
            <Typography variant="h6" noWrap className="toolbar-app-name">
              {t("common:text.title")}
            </Typography>
          </Toolbar>
        </AppBar>
        <MenuItem key="langSelect" style={{ padding: 0 }}>
          <UILangSelector drawerMenu={true} />
        </MenuItem>
        <Divider />
        {!isAuthenticated ? (
          <div>
            {_.get(Settings, "altHome.sidebar", false) && (
              <div>
                <Divider />
                <List>
                  <ListItem onClick={openContractsCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_contract_title')}
                      </Typography>
                    </ListItemText>
                    {contracts_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={contracts_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="newCustomer"
                          onClick={() =>
                            dispatchRoute(contraction_url)
                          }
                        >
                          {t('common:text.altHome_contract_create')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="contracts"
                          onClick={() =>
                            dispatchRoute(t('common:url.contracts'))
                          }
                        >
                          {t('common:text.altHome_contract_view')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="download"
                          onClick={() => contractFun("download")}
                        >
                          {t('common:text.altHome_contract_download')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="powChange"
                          onClick={() => contractFun("powChange")}
                        >
                          {t('common:text.altHome_contract_power')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="ibanChange"
                          onClick={() => contractFun("ibanChange")}
                        >
                          {t('common:text.altHome_contract_iban')}
                        </MenuItem>
                      </div>
                      {features.notificacionAddressChange && (
                        <div>
                          <MenuItem
                            key="notifAddrChange"
                            onClick={() =>
                              contractFun("notifAddrChange")
                            }
                          >
                            {t('common:text.altHome_contract_contact')}
                          </MenuItem>
                        </div>
                      )}
                      {features.noCesionSips && (
                        <div>
                          <MenuItem
                            key="noCesionSips"
                            onClick={() =>
                              contractFun("noCesionSips")
                            }
                          >
                            {t('common:text.altHome_contract_sips')}
                          </MenuItem>
                        </div>
                      )}
                    </List>
                  </Collapse>
                </List>
                <Divider />
                <List>
                  <ListItem onClick={openInvoicesCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_invoices_title')}
                      </Typography>
                    </ListItemText>
                    {invoices_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={invoices_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="invoiceView"
                          onClick={() => goToInvoices()}
                        >
                          {t('common:text.altHome_invoices_view')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="invoiceDownload"
                          onClick={() => dispatchRoute(t('common:url.invoices'))}
                        >
                          {t('common:text.altHome_invoices_download')}
                        </MenuItem>
                      </div>
                      {_.get(Settings, 'invoices.enablePayment', false) &&
                        <div>
                          <MenuItem
                            key="invoicePayOnline"
                            onClick={() => dispatchRoute(t('common:url.invoices'))}
                          >
                            {t('common:text.altHome_invoices_payonline')}
                          </MenuItem>
                        </div>
                      }
                    </List>
                  </Collapse>
                </List>
                <Divider />
                <List>
                  <ListItem onClick={openConsumptionCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_consumption_title')}
                      </Typography>
                    </ListItemText>
                    {consumption_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={consumption_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="consumption"
                          onClick={() => contractFun("consumption")}
                        >
                          {t('common:text.altHome_consumption_view')}
                        </MenuItem>
                      </div>
                    </List>
                  </Collapse>
                </List>
              </div>
            )}
            {!features.oidc && (
              <div>
                <Divider />
                <MenuItem
                  key="oidc"
                  onClick={() => dispatchRoute(t('common:url.login'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Person color="action" />
                  </Box>
                  {t('common:text.login_title')}
                </MenuItem>
              </div>
            )}
            {features.signup && (
              <div>
                <Divider />
                <MenuItem
                  key="signup"
                  onClick={() => dispatchRoute(t('common:url.activate'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <PersonAdd color="action" />
                  </Box>
                  {t('common:text.login_view_button_activate')}
                </MenuItem>
              </div>
            )}
            {_.get(Settings, "features.sidebarContract", false) && (
              <div>
                <Divider />
                <MenuItem
                  key="newCustomer"
                  onClick={() => dispatchRoute(contraction_url)}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <AddBox color="action" />
                  </Box>
                  {t('common:text.new_customer_title')}
                </MenuItem>
              </div>
            )}
            {features.unsignedContact && features.contact && (
              <div>
                <Divider />
                <MenuItem
                  key="unsigned_contact"
                  onClick={() => dispatchRoute(t('common:url.unsigned_contact'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Message color="action" />
                  </Box>
                  {t('common:text.contact_title')}
                </MenuItem>
              </div>
            )}
            {_.get(Settings, "comparator.enabled", false) && (
              <div>
                <Divider />
                <MenuItem
                  key="priceComparator"
                  onClick={() => dispatchRoute(t('common:url.priceComparator'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <EuroSymbol color="action" />
                  </Box>
                  {t('common:text.menu_price_comparator_title')}
                </MenuItem>
              </div>
            )}
            {_.get(Settings, "powerCalculator.enabled", false) && (
              <div>
                <Divider />
                <MenuItem
                  key="powerCalculator"
                  onClick={() => dispatchRoute(t('common:url.powerCalculator'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <EuroSymbol color="action" />
                  </Box>
                  {t('common:text.menu_power_calculator_title')}
                </MenuItem>
              </div>
            )}
            <Divider />
          </div>
        ) : (
          <div>
            {_.get(Settings, "altHome.sidebar", false) ? (
              <div>
                <Divider />
                <List>
                  <ListItem onClick={openContractsCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_contract_title')}
                      </Typography>
                    </ListItemText>
                    {contracts_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={contracts_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="newCustomer"
                          onClick={() =>
                            dispatchRoute(contraction_url)
                          }
                        >
                          {t('common:text.altHome_contract_create')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="contracts"
                          onClick={() =>
                            dispatchRoute(t('common:url.contracts'))
                          }
                        >
                          {t('common:text.altHome_contract_view')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="download"
                          onClick={() => contractFun("download")}
                        >
                          {t('common:text.altHome_contract_download')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="powChange"
                          onClick={() => contractFun("powChange")}
                        >
                          {t('common:text.altHome_contract_power')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="ibanChange"
                          onClick={() => contractFun("ibanChange")}
                        >
                          {t('common:text.altHome_contract_iban')}
                        </MenuItem>
                      </div>
                      {features.notificacionAddressChange && (
                        <div>
                          <MenuItem
                            key="notifAddrChange"
                            onClick={() =>
                              contractFun("notifAddrChange")
                            }
                          >
                            {t('common:text.altHome_contract_contact')}
                          </MenuItem>
                        </div>
                      )}
                      {features.noCesionSips && (
                        <div>
                          <MenuItem
                            key="noCesionSips"
                            onClick={() =>
                              contractFun("noCesionSips")
                            }
                          >
                            {t('common:text.altHome_contract_sips')}
                          </MenuItem>
                        </div>
                      )}
                    </List>
                  </Collapse>
                </List>
                <Divider />
                <List>
                  <ListItem onClick={openInvoicesCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_invoices_title')}
                      </Typography>
                    </ListItemText>
                    {invoices_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={invoices_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="invoicesView"
                          onClick={() => goToInvoices()}
                        >
                          {t('common:text.altHome_invoices_view')}
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          key="invoicesDownload"
                          onClick={() => dispatchRoute(t('common:url.invoices'))}
                        >
                          {t('common:text.altHome_invoices_download')}
                        </MenuItem>
                      </div>
                      {_.get(Settings, 'invoices.enablePayment', false) &&
                        <divenablePayment>
                          <MenuItem
                            key="invoicesPayOnline"
                            onClick={() => dispatchRoute(t('common:url.invoices'))}
                          >
                            {t('common:text.altHome_invoices_payonline')}
                          </MenuItem>
                        </divenablePayment>
                      }
                    </List>
                  </Collapse>
                </List>
                <Divider />
                <List>
                  <ListItem onClick={openConsumptionCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_consumption_title')}
                      </Typography>
                    </ListItemText>
                    {consumption_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={consumption_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="consumption"
                          onClick={() => contractFun("consumption")}
                        >
                          {t('common:text.altHome_consumption_view')}
                        </MenuItem>
                      </div>
                    </List>
                  </Collapse>
                </List>
              </div>
            ) : (
              <div>
                <Divider />
                <MenuItem
                  key="contracts"
                  onClick={() => dispatchRoute(t('common:url.contracts'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <PermIdentity color="action" />
                  </Box>
                  {t('common:text.contracts_title')}
                </MenuItem>
                <Divider />
                <MenuItem
                  key="invoices"
                  onClick={() => dispatchRoute(t('common:url.invoices'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Receipt color="action" />
                  </Box>
                  {t('common:text.invoices_title')}
                </MenuItem>
              </div>
            )}
            {features.reclamATCMenu && (
              <div>
                <Divider />
                <MenuItem
                  key="reclamations"
                  onClick={() => dispatchRoute(t('common:url.reclamations'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Feedback color="action" />
                  </Box>
                  {t('common:text.reclamations_title')}
                </MenuItem>
              </div>
            )}
            {features.contact && (
              <div>
                <Divider />
                <MenuItem
                  key="contact"
                  onClick={() => dispatchRoute(t('common:url.contact'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Message color="action" />
                  </Box>
                  {t('common:text.contact_title')}
                </MenuItem>
              </div>
            )}
            {Settings?.features?.enableServerElements && (
              <DinamicServerNavigation
                wrapperComponent={({ children }) => (
                  <div>
                    <Divider />
                    {children}
                  </div>
                )}
                clickableComponent={MenuItem}
              />
            )}
            {is_debug() && (
              <div>
                <Divider />
                <MenuItem
                  key="about"
                  onClick={() => dispatchRoute(t('common:url.about'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <PermDeviceInformation color="action" />
                  </Box>
                  {t('common:text.about_title')}
                </MenuItem>

                <Divider />
              </div>
            )}
            {_.get(Settings, "altHome.sidebar", false) ? (
              <div>
                <Divider />
                <List>
                  <ListItem onClick={openProfileCollapse} style={{ cursor: 'pointer' }}>
                    <ListItemText>
                      <Typography variant="h6" noWrap>
                        {t('common:text.altHome_my_profile')}
                      </Typography>
                    </ListItemText>
                    {profile_open ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  <Collapse
                    in={profile_open}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <div>
                        <MenuItem
                          key="changePassword"
                          onClick={() =>
                            dispatchRoute(t('common:url.changePassword'))
                          }
                        >
                          <Box
                            component="span"
                            mr={1}
                            style={{ height: 24 }}
                          >
                            <VpnKey color="action" />
                          </Box>
                          {t('common:text.change_password_view_title')}
                        </MenuItem>
                        <MenuItem
                          key="logout"
                          onClick={(e) => logout(e)}
                        >
                          <Box
                            component="span"
                            mr={1}
                            style={{ height: 24 }}
                          >
                            <Close color="action" />
                          </Box>
                          {t('common:text.logout_title')}
                        </MenuItem>
                      </div>
                    </List>
                  </Collapse>
                </List>
                {features.contact &&
                  <div>
                    <Divider />
                    <MenuItem
                      key="contact"
                      onClick={() => dispatchRoute(t('common:url.contact'))}
                    >
                      <Box component="span" mr={1} style={{ height: 24 }}>
                        <Receipt color="action" />
                      </Box>
                      {t('common:text.contact_title')}
                    </MenuItem>
                    {_.get(Settings, "features.CRMcases", false) &&
                      <div>
                        <Divider />
                        <MenuItem
                          key={"crm_manage"}
                          onClick={() => {
                            dispatchRoute(t('common:url.CRM_list'));
                          }}
                        >
                          <Box component="span" mr={1} style={{ height: 24 }}>
                            <Assignment color="action" />
                          </Box>
                          {t('common:text.crm_manage_requests')}
                        </MenuItem>
                      </div>
                    }
                  </div>
                }
              </div>
            ) : (
              <div>
                <Divider />
                <MenuItem
                  key="changePassword"
                  onClick={() => dispatchRoute(t('common:url.changePassword'))}
                >
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <VpnKey color="action" />
                  </Box>
                  {t('common:text.change_password_view_title')}
                </MenuItem>
                <Divider />
                <MenuItem key="logout" onClick={(e) => logout(e)}>
                  <Box component="span" mr={1} style={{ height: 24 }}>
                    <Close color="action" />
                  </Box>
                  {t('common:text.logout_title')}
                </MenuItem>
              </div>
            )}
            <Divider />
          </div>
        )}
      </Drawer>

      <AppBar position="static" className="drawer-app-bar">
        <Toolbar>
          <IconButton
            id="menu_drawer_button"
            aria-label="Menu drawer button"
            edge="start"
            color="inherit"
            onClick={() => openNav()}
            className="toolbar-icon-button"
          >
            <Menu />
          </IconButton>
          <Grid container style={{ height: "100%" }}>
            <LogoBar />
          </Grid>
          {isAuthenticated ? user : null}
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default Header;